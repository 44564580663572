/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Button, Typography } from '@mui/material';

const Header = ({ title, to }: any) => {
  const navigate = useNavigate();

  const handleClick = (to: string | undefined) => {
    navigate(`${to}`);
  };

  return (
    <>
      <Typography variant='h4'>{title}</Typography>
      {to && <Button color='primary' variant='contained' onClick={() => handleClick(to)}>Home</Button>}
      
    </>
  );
};

export default Header;