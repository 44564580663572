/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from 'react';
import SumsubWebSdk from '@sumsub/websdk-react';
import { HmacSHA256 } from 'crypto-js';
import Hex from 'crypto-js/enc-hex';
import { Container } from '@mui/material';
import Header from 'component/Header';

const SumsubPoc = () => {
  const handler = (): any => {
    return '';
  };

  const createSignature = () => {
    console.log('Creating a signature for the request...');

    var ts = Math.floor(Date.now() / 1000);
    const bytes = HmacSHA256('sha256',  'EmajgepvdP59n4qhJvno9c4J1AnYHOkQ');
    const signature = bytes.toString(Hex);

    console.log('X-App-Access-Ts', ts)
    console.log('X-App-Access-Sig', signature);
  }

  useEffect(() => {
    createSignature()
  }, []);

  return (
    <Container>
      <Header title='Sumsub POC' to='/' />

      <SumsubWebSdk
        accessToken={'_act-sbx-fe763e98-11a0-43bc-b409-b80e332145d3'}
        expirationHandler={handler}
      />
    </Container>
  );
};

export default SumsubPoc;