import { Routes as Switch, Route } from 'react-router-dom';
import Dashboard from 'modules/Dashboard';
import SumsubPoc from 'modules/SumsubPoc';
import LeantechPoc from 'modules/LeantechPoc';

const Routes = () => {
  return (
    <Switch>
      <Route path='/' element={<Dashboard />} />
      <Route path='/poc-sumsub' element={<SumsubPoc />} />
      <Route path='/poc-leantech' element={<LeantechPoc />} />
    </Switch>
  );
};

export default Routes;