/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Button, Container, Stack } from '@mui/material';
import Header from 'component/Header';

const Dashboard = () => {
  const navigate = useNavigate();

  const handleClick = (to: string | undefined) => {
    navigate(`${to}`);
  };

  return (
    <Container sx={{p: 8}}>
      <Header title="POC's" />

      <Stack spacing={3} sx={{mt: 3, ml: 'auto', mr: 'auto', width: 200, justifyContent: 'center'}}>
        <Button color='primary' variant='contained' onClick={() => handleClick('/poc-sumsub')}>Test Sumsub</Button>
        <Button color='primary' variant='contained' onClick={() => handleClick('/poc-leantech')}>Test Leantech</Button>
      </Stack>
    </Container>
  );
};

export default Dashboard;