/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, ChangeEvent } from 'react';
import { Helmet } from 'react-helmet';
import { Container, Grid, TextField, Button } from '@mui/material';
import Header from 'component/Header';

const LeantechPoc = () => {
  const [appToken, setAppToken] = useState<string>('');
  const [customerId, setCustomerId] = useState<string>('');
  const [endUserId, setEndUserId] = useState<string>('');
  const [paymentIntentId, setPaymentIntentId] = useState<string>('');
  const [paymentDestinationIdForAuthorize, setPaymentDestinationIdForAuthorize] = useState<string>('');
  const [paymentIntentIdForAuthorize, setPaymentIntentIdForAuthorize] = useState<string>('');

  const handleClick = () => {
    if (window && (window as any).Lean) {
      (window as any).Lean.pay({
        app_token: appToken,
        payment_intent_id: paymentIntentId,
        sandbox: 'true'
      });
    }
  };

  const handleSetupPaymentClick = () => {
    if (window && (window as any).Lean) {
      (window as any).Lean.connect({
        app_token: appToken,
        customer_id: customerId,
        end_user_id: endUserId,
        payment_destination_id: paymentDestinationIdForAuthorize,
        permissions: ["identity","balance","accounts","transactions","payments"],
        sandbox: "true",
      });
    }
  };

  const handleauthorisePaymentClick = () => {
    if (window && (window as any).Lean) {
      (window as any).Lean.authorize( {
        app_token: appToken,
        payment_intent_ids: [paymentIntentIdForAuthorize],
        customer_id: customerId,
        end_user_id: endUserId,
        sandbox: "true"
      });
    }
  };

  const handleAppToken = (event: ChangeEvent<HTMLInputElement>) => {
    setAppToken(event.target.value);
  };

  const handleCustomerId = (event: ChangeEvent<HTMLInputElement>) => {
    setCustomerId(event.target.value);
  };

  const handleEndUserId = (event: ChangeEvent<HTMLInputElement>) => {
    setEndUserId(event.target.value);
  };

  const handlePaymentIntentId = (event: ChangeEvent<HTMLInputElement>) => {
    setPaymentIntentId(event.target.value);
  };

  const handlePaymentDestinationIdForPayments = (event: ChangeEvent<HTMLInputElement>) => {
    setPaymentDestinationIdForAuthorize(event.target.value);
  };

  const handlePaymentIntentIdForAuthorize = (event: ChangeEvent<HTMLInputElement>) => {
    setPaymentIntentIdForAuthorize(event.target.value);
  };

  return (
    <Container>
      <Helmet>
        <script src='https://cdn.leantech.me/link/sdk/web/latest/Lean.min.js' />
      </Helmet>
      <Header title='Leantech POC' to='/' />

      <Grid container spacing={3} sx={{mt: 3}}>
        <Grid item xs={12}>
          <TextField label='App Token' variant='outlined' onChange={handleAppToken} sx={{width: '40%'}} />
        </Grid>
        <Grid item xs={12}>
          <TextField label='Customer Id' variant='outlined' onChange={handleCustomerId} sx={{width: '40%'}} />
        </Grid>
        <Grid item xs={12}>
          <TextField label='End User Id' variant='outlined' onChange={handleEndUserId} sx={{width: '40%'}} />
        </Grid>
        <Grid item xs={12}>
          <TextField label='Payment Intent Id' variant='outlined' onChange={handlePaymentIntentId} sx={{width: '40%'}} />
        </Grid>
        <Grid item xs={12}>
          <Button color='primary' variant='contained' onClick={() => handleClick()}>Lean Pay</Button>
        </Grid>
        <Grid item xs={12}>
          <TextField label='Payment Destination Id For Payments' variant='outlined' onChange={handlePaymentDestinationIdForPayments} sx={{width: '40%'}} />
        </Grid>
        <Grid item xs={12}>
          <Button color='primary' variant='contained' onClick={() => handleSetupPaymentClick()}>Set up payments</Button>
        </Grid>
        <Grid item xs={12}>
          <TextField label='Payment Intent Id For Authorize' variant='outlined' onChange={handlePaymentIntentIdForAuthorize} sx={{width: '40%'}} />
        </Grid>
        <Grid item xs={12}>
          <Button color='primary' variant='contained' onClick={() => handleauthorisePaymentClick()}>Authorize payments</Button>
        </Grid>
      </Grid>
      <div id='lean-link'></div>
    </Container>
  );
};

export default LeantechPoc;